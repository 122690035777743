<template>
  <v-footer id="gavcom-core-footer" inset :app="$vuetify.breakpoint.smAndUp">
    <v-container :class="{'pb-0' : $vuetify.breakpoint.smAndDown}">
      <v-row justify="center" align="center" no-gutters class="body-1 font-weight text-center">
        <v-col v-for="(link, i) in links" :key="i" align="center" class="text-center mb-2 mb-md-0" cols="auto">
          <router-link 
            :to="link.to" 
            class="mr-0 grey--text text--darken-3"
            style="padding: 10px 18px 10px 16px; font-size: 14px!important;"
          >
            {{link.text}}
          </router-link>
        </v-col>

        <v-spacer class="hidden-sm-and-down" />

        <v-col cols="12" md="auto">
          <div class="body-1 font-weight pt-0 text-center">
            Copyright &copy; {{ new Date().getFullYear() }} —
            <a
              href="https://www.globaldesk.com.bo"
              class="black--text"
              rel="noopener"
              target="_blank"
            ><strong>GLOBAL DESK</strong></a>
            <span>— Todos los derechos reservados.</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "GavcomCoreFooter",

  data: () => ({
    links: [
      {
        to: { name: 'Home'},
        text: "ir al Inicio"
      },
      // {
      //   to: { name: 'Precios'},
      //   text: "Ver Planes"
      // }
    ]
  })
};
</script>

<style lang="sass">
#gavcom-core-footer
    a
        text-decoration: none
        text-transform: uppercase
</style>